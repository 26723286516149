import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Homepage from "./Homepage";
import PersonalPg from './PersonalPg';
import ContactPg from './ContactPg';
import Calc from './Calc';
import BirdSong from './BirdSong';
import ListItemsPg from './ListItemsPg';
import SortListPg from './SortListPg';
import Form1Pg from './Form1Pg';
import Context1Pg from "./Context1Pg";
import GiftCardPg from './GiftCardPg';
import BtcDataPg from './BtcDataPg';
import DialogPg from './DialogPg';
import MouseRenderPg from './MouseRenderPg';
import TimerPg from './TimerPg';
import PomodoroPg from './PomodoroPg';
import VideoPlPg from './VideoPlPg';
import TestPg from './TestPg';
import MapPg from './MapPg';

// New interface for BrowserRouter v.6.8
import {createBrowserRouter, RouterProvider} from "react-router-dom";

// Create new router for application
const router = createBrowserRouter([
  {
    path: "/",
    element: <App title='Start application'/>,
    //loader: rootLoader,
    children: [
      {
        index: true, // the index element of path
        element: <Homepage />,
        //loader: ,
      },
      {
        path: "personal",
        element: <PersonalPg />,
        //loader: ,
      },
      {
        path: "birdsong",
        element: <BirdSong />,
      },
      {
        path: "calc",
        element: <Calc />,
        //loader: ,
      },
      {
        path: "contact",
        element: <ContactPg />,
      },
      {
        path: "list",
        element: <ListItemsPg />,
      },
      {
        path: "s_list",
        element: <SortListPg />,
      },
      {
        path: "form1",
        element: <Form1Pg />,
      },
      {
        path: "context1",
        element: <Context1Pg />,
      },
      {
        path: "gift_card",
        element: <GiftCardPg />,
      },
      {
        path: "btcdata",
        element: <BtcDataPg />,
      },
      {
        path: "dialog",
        element: <DialogPg />,
      },
      {
        path: "mouse_rend",
        element: <MouseRenderPg />,
      },
      {
        path: "timer",
        element: <TimerPg />,
      },
      {
        path: "pomodoro",
        element: <PomodoroPg />,
      },
      {
        path: "video_pl",
        element: <VideoPlPg />,
      },
      {
        path: "test",
        element: <TestPg />,
      },
      {
        path: "map",
        element: <MapPg />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
