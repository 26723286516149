/*****************************************
Stanislav S. Polivtsev
start: 21 March 2023

Map page
ver.1.1 Notification, ScreenLock, useEffect 
******************************************/
import { useState, useEffect } from "react";
import dingAudio from './assets/sound/ding.mp3'

const dingSingle = new Audio(dingAudio);
const dingSinglePlay = () => {
  dingSinglePlay.volume=0.02;
  dingSingle.play();
};

const aLat = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}°${quotient2}'${quotient3.toFixed(1)}"${angle>=0?'N':'S'}`  
}

const aLng = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}°${quotient2}'${quotient3.toFixed(1)}"${angle>=0?'E':'W'}`  
}

const aLatU = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}%C2%B0${quotient2}'${quotient3.toFixed(1)}%22${angle>=0?'N':'S'}`  
}

const aLngU = (angle) => {
  const a=Math.abs(angle);
  const quotient1 = Math.floor(a);
  const reminder1 = a % 1;
  let b = 1/60;
  let quotient2 = Math.floor(reminder1/b);
  let reminder2 = reminder1 % b;
  b /= 60;
  let quotient3 = reminder2/b;
  return `${quotient1}%C2%B0${quotient2}'${quotient3.toFixed(1)}%22${angle>=0?'E':'W'}`  
}


const MapPg = () => {
  // String array for diagnostic information, show on test page
  const [pstLog, setPstLog] = useState([]);
  const [testLog, setTestLog] = useState([]);
  const [trackerActive, setTrackerActive] = useState(false);
  const [curPst, setCurPst] = useState();
  
  const navigator = window.navigator;
  const [tInterval, setTInterval] = useState(60);
  
  const pstToStr = (pst) => {
    let lStr = `Latitude: ${pst.latitude} °, Longitude: ${pst.longitude} °`;
    lStr += (pst.altitude) ? `, Altitude: ${pst.altitude.toFixed(1)} m` : '';
    lStr += (pst.accuracy) ? `, Accuracy: ${pst.accuracy.toFixed(1)} m` : '';
    lStr += (pst.altitudeAccuracy) ? `, Altitude accuracy: ${pst.altitudeAccuracy.toFixed(1)} m` : '';
    lStr += (pst.heading) ? `, Heading: ${pst.heading} °` : '';
    lStr += (pst.speed) ? `, Speed: ${pst.speed} m/s` : '';
    return lStr
  };
  
  const pstToStrDN = (pst) => {
    // Convert position object to string lat, lng in Decimal Notation
    return `${pst.latitude.toFixed(6)},${pst.longitude.toFixed(6)}`
  }

  const pstToStrHN = (pst) => {
    // Convert position object to string lat, lng in Historical Notation
    return `lat: ${aLat(pst.latitude)} lng: ${aLng(pst.longitude)}`
  }

  const pstToStrHNP = (pst) => {
    // Convert position object to string lat, lng in Historical Notation
    return `${aLat(pst.latitude)}+${aLng(pst.longitude)}`
  }

  const pstToGMapS = (pst) => {
    // Convert position object to string for gmap search based on Historical Notation
    return `${aLatU(pst.latitude)}+${aLngU(pst.longitude)}+(My%20location)`
  }

  //50%C2%B026'46.8%22N+30%C2%B035'55.5%22E+(My%20location)

  const pstToGMapLink = (pst) => {
    return `https://www.google.com/maps/place/${pstToStrHNP(pst)}/@${pstToStrDN(pst)},18z?output=embed`
  }
  
  const toPstLog = (pst) => {
    // Create TimeStamp for log record
    setPstLog(tl => [{pst: pst,}, ...tl]);
  }



  const toLog = (textStr) => {
    // Create TimeStamp for log record
    const key1 = Date.now();
    const c = new Date(key1);
    const logStr = `${c.toLocaleTimeString("de-DE")}: ${textStr}`
    const rKey = Math.floor(Math.random()*10000)+textStr.slice(0,10)+textStr.slice(-10,-1); 
    setTestLog(tl => [{key: key1+'-'+rKey, strL: logStr}, ...tl]);
  }


  /* ***** Timer event handler ***** */
  useEffect(() => {
    const getLocation = () => {
      // const status = document.querySelector("#status");
      // const mapLink = document.querySelector("#map-link");
      // mapLink.href = "";
      // mapLink.textContent = "";
      // Define GPS callback functions
      function success(position) {
        const pst = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          altitude: position.coords.altitude,
          accuracy: position.coords.accuracy,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          speed: position.coords.speed,
          timestamp: position.timestamp,
        };   
        //status.textContent = "";
        //mapLink.href = `https://www.openstreetmap.org/#map=18/${latitude}/${longitude}`;
        //mapLink.textContent = `Latitude: ${latitude} °, Longitude: ${longitude} °`;
        toPstLog(pst);
        toLog(pstToStr(pst));
      }
    
      function error() {
        //status.textContent = "Unable to retrieve your location";
        toLog("Unable to retrieve your location");
      }
    
      if (!navigator.geolocation) {
        // status.textContent = "Geolocation is not supported by your browser";
        toLog("Geolocation is not supported by your browser");
      } else {
        // status.textContent = "Locating…";
        navigator.geolocation.getCurrentPosition(success, error);
      }
    }
    
    let interval;
    if (trackerActive) {
      interval = setInterval(() => {
        const logStr = 'Check'; 
        console.log(logStr);
        toLog(logStr);
        getLocation();
        dingSinglePlay();
        }, tInterval*1000);
      console.log(`Activate timer with interval ${tInterval} sec`);
      toLog(`Activate timer with interval ${tInterval} sec`);
      getLocation();
    } else {
      console.log(`Timer inactive, timer interval ${tInterval} sec`);
      toLog(`Timer inactive, timer interval ${tInterval} sec`);
    }
    
    return () => {
      console.log('Clear current timer function');
      toLog('Clear current timer function');
      if (interval) {clearInterval(interval);toLog('Clear time interval function');
    };
    }
  }, [trackerActive, tInterval, navigator.geolocation]);
  
  const PosMap = props => {
    if (props.pst) {
      return (
        <div>
          <div>{pstToStr(props.pst)}</div>
          <div style={{width: "100%"}}>
            <iframe title="GoogleMap" style={{width:"100%", height:"40vh", frameBorder:"0", margin:"0"}} 
              src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${pstToGMapS(props.pst)}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
              loading="lazy">
              <a href="https://www.maps.ie/distance-area-calculator.html">measure area maps</a>
            </iframe>
          </div>
        </div>
      )
    } else {
      return <div>Select position</div>
    }  
  }

/*
worked link for embed Google Map 
https://maps.google.com/maps?width=100%25&height=600&hl=en&q=50%C2%B026'46.8%22N+30%C2%B035'55.5%22E+(My%20location)&t=&z=14&ie=UTF8&iwloc=B&output=embed
*/

  return (
    <div style={{maxWidth:'1200px', textAlign:'left',margin:'0 2em',}}>
      <h1>Map page</h1>
      <PosMap pst={curPst}></PosMap>
      <div className="Field">
        <button onClick={(e)=>{setTrackerActive(c=>!c);}}
          >{(trackerActive) ? 'Stop tracker':'Start tracker'}
        </button>  
        <label htmlFor="timeout">Tracker interval</label>
        <select
          style={{ margin:"0.5em", padding:"0.5em", borderRadius:"1em",}} 
          value={tInterval} id="timeout"
          onChange={(e) => setTInterval(e.target.value)}
        >
          <option value="20">20s</option>
          <option value="60">60s</option>
          <option value="120">2min</option>
          <option value="300">5min</option>
        </select>
        <button onClick={(e)=>setPstLog([])} >Clear log</button>
        
      </div>
      <h3>Geo positions Log <em> {(pstLog.length>=1)?'from '+new Date(pstLog[pstLog.length-1].pst.timestamp).toLocaleTimeString("de-DE"):''}</em></h3>
      <div style={{fontSize:"0.9em",}}>
        {pstLog.map(logObj => 
          <div key={logObj.pst.timestamp} 
            onClick={(e)=>{setCurPst(logObj.pst)}}>
            { `${new Date(logObj.pst.timestamp).toLocaleTimeString("de-DE")} - ${pstToStrHN(logObj.pst)} `}
            <a href={pstToGMapLink(logObj.pst)} target="_blank" rel="noreferrer">Open</a>
          </div>)}
      </div>
      <h3>Log:</h3>
      <div style={{fontSize:"0.5em",fontStyle:"italic",}}>
        {testLog.map(logArray => <div key={logArray.key}>{logArray.strL}</div>)}
      </div>
      
    </div>
  );
}

export default MapPg;

/* Control element 
        <label htmlFor="timeout">Tracker interval</label>
        <input 
          type="number" id="timeout"
          style={{display: "inline-block", margin:"0.5em", width:"5em",}} 
          min="20" step="20" 
          name="timeout" value={tInterval}
          onChange= {(e) =>{let val = e.target.value>=20 ? e.target.value : 20;
            setTInterval(val); 
            console.log('time interval=', val);}} 
        />
*/